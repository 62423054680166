import { IntegrationApplication } from '@wix/members-area-integration-kit';
import { ProfileType } from './blog-writer';

export enum PageType {
  StandAlone = 'stand-alone',
  Custom = 'custom',
  TPA = 'tpa',
  WidgetPlugin = 'widget-plugin',
}

export type IntegrationApplicationWithoutWidgetId = Omit<IntegrationApplication, 'widgetId'>;

interface MembersAreaPage {
  pageType: PageType.Custom | PageType.StandAlone | PageType.TPA;
  title: string;
  integrationApplication: IntegrationApplicationWithoutWidgetId;
  isInstalled: boolean;
}

interface WidgetPluginPage {
  pageType: PageType.WidgetPlugin;
  title: string;
  integrationApplication: IntegrationApplication;
  isInstalled: boolean;
}

export type Page = MembersAreaPage | WidgetPluginPage;

export type PageToModify =
  | Pick<MembersAreaPage, 'pageType' | 'integrationApplication'>
  | Pick<WidgetPluginPage, 'pageType' | 'integrationApplication'>;

export type UpdatableProfileType = ProfileType.BWP_ALL | ProfileType.BWP_ONLY;

export interface PagesModificationPayload {
  appsToInstall: PageToModify[];
  appsToRemove: PageToModify[];
  profilePageType?: UpdatableProfileType | null;
}
