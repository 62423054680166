import { ComponentRef, EditorReadyOptions, EditorSDK, PageRef } from '@wix/platform-editor-sdk';
import { WIX_STORES } from '@wix/app-definition-ids';

import { allSettled } from '../../../../utils/promises';
import { APP_TOKEN, MENU_IDS } from '../../../constants';
import { createController } from '../../../wrappers/controllers';
import { createLoginIconsMenu, createLoginMenu } from '../../../wrappers/menus';
import { addLoginButton } from '../../../wrappers/components';

const getRefsForLoginMenu = async (editorSDK: EditorSDK) => {
  const [masterRef, headerRef]: [PageRef, ComponentRef] = await allSettled([
    editorSDK.siteSegments.getSiteStructure(APP_TOKEN),
    editorSDK.siteSegments.getHeader(APP_TOKEN),
  ]);
  const controllerRef = await createController(editorSDK, masterRef);

  return { headerRef, controllerRef };
};

const updateGlobalController = async (editorSDK: EditorSDK, controllerRef: ComponentRef) => {
  const { config } = await editorSDK.controllers.getData('', {
    controllerRef,
    scope: 'APP',
  });

  await editorSDK.controllers.saveConfiguration('', {
    controllerRef,
    scope: 'APP',
    config: { ...config, isMembersAreaV2: true },
  });
};

export const addLoginMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const isStoresInstallation = options.origin.info?.appDefinitionId === WIX_STORES;
  const { headerRef, controllerRef } = await getRefsForLoginMenu(editorSDK);

  await updateGlobalController(editorSDK, controllerRef);

  await Promise.all([
    createLoginMenu(editorSDK),
    createLoginIconsMenu(editorSDK),
    addLoginButton(editorSDK, controllerRef!, headerRef, isStoresInstallation),
  ]);
};

export const addMembersSubMenu = async (editorSDK: EditorSDK) => {
  await editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: MENU_IDS.SUB_MENU_ID,
  });
};
