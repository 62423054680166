import { EditorSDK } from '@wix/platform-editor-sdk';

import { MEMBERS_LIST_PAGE_ID } from '../../../constants';
import { getAllPages } from '../../../wrappers/pages';

const getMembersListPageData = async (editorSDK: EditorSDK) => {
  const allPages = await getAllPages({ editorSDK });
  return allPages.find(({ tpaPageId }) => tpaPageId === MEMBERS_LIST_PAGE_ID);
};

export const isMembersListInstalled = async (editorSDK: EditorSDK) => {
  const membersListPageData = await getMembersListPageData(editorSDK);
  return Boolean(membersListPageData);
};
