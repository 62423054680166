import { EditorSDK, PageSettings } from '@wix/platform-editor-sdk';

import { buildPanelUrl } from '../../../services/panel-url-builder';

const PERMISSIONS_PLATFORMISED: PageSettings = {
  title: 'Permissions',
  // @ts-expect-error
  event: 'pagePermissions',
  icon: 'page_dynamic_icon',
  helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
  type: 'permissions',
};

const getPaneInfoAction = async (editorSDK: EditorSDK): Promise<PageSettings> => ({
  title: 'Page Info',
  // @ts-expect-error
  event: 'pageInfo',
  icon: 'page_dynamic_icon',
  url: await buildPanelUrl(editorSDK, 'pageInfo.html'),
  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
  type: 'page_info',
});

const getSeoSettingsAction = async (editorSDK: EditorSDK): Promise<PageSettings> => ({
  title: 'SEO Basics',
  // @ts-expect-error
  event: 'pageSEO',
  icon: 'page_dynamic_icon',
  url: await buildPanelUrl(editorSDK, 'pageSEO.html'),
  helpId: 'a621022f-17fa-433a-b4f5-d43994e8c4f6',
  type: 'seo',
});

export { PERMISSIONS_PLATFORMISED, getPaneInfoAction, getSeoSettingsAction };
