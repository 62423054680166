import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, MembersAreaAppId } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';
import { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';

export const groupDefinitionsByMethod = (
  definitions: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
) => {
  const definitionsGroupedByMethod = {
    widgetPlugins: [] as IntegrationApplication[],
    siteApps: [] as IntegrationApplicationWithoutWidgetId[],
  };

  definitions.forEach((definition) => {
    if (definition.method === 'addApplicationToSite') {
      definitionsGroupedByMethod.siteApps.push(definition);
    } else {
      definitionsGroupedByMethod.widgetPlugins.push(definition as IntegrationApplication);
    }
  });

  return definitionsGroupedByMethod;
};

export const getDefinitions = async (editorSDK: EditorSDK, membersAreaAppIds: MembersAreaAppId[]) => {
  return getAppDefinitions({
    editorSDK,
    applications: membersAreaAppIds,
    i18next: createInstance(),
  });
};
